/*
 * Ninja Forms - Form Display Structure
 */

.ninja-forms-form-wrap *,
.ninja-forms-form-wrap *:after,
.ninja-forms-form-wrap *:before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
}

.nf-response-msg {
    display: none;
}

.nf-field-container,
#ninja_forms_required_items {
    clear: both;
    position: relative;
    margin-bottom: 25px;
    &::after {
        clear: both;
        content: "";
        display: block;
    }
    &.hidden-container,
    &.hr-container {
        margin: 0;
        height: auto;
        .nf-field-label {
            display: none;
        }
    }
    &.html-container {
        .nf-field-label {
            display: none;
        }
    }
}

.nf-field-label {
    .nf-label-span,
    label {
        font-weight: bold;
        margin: 0;
        padding: 0;
    }
}
.nf-field-element {
    position: relative;
    input,
    select,
    textarea {
        margin: 0;
        width: auto;
        height: auto;
        vertical-align: inherit;
    }
    textarea {
        height: 200px;
    }
}
.nf-field-description {
    p:last-child {
        margin: 0;
    }
}
.nf-help {
    background: url( '../img/help-info.png' ) no-repeat;
    display: inline-block;
    width: 16px;
    height: 16px;
}

.nf-input-limit {
    font-size: 12px;
    margin-top: 5px;
}
.nf-static {
    padding: 0 10px;
}
.nf-after-field {
    .nf-error-msg {
        margin-right: 10px;
        //text-align: right;
    }
}

/* ---------------------------------
Screen Reader CSS
----------------------------------*/
.nf-sr-only {
    border: 0;
    clip: rect(0, 0, 0, 0);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    opacity: 0;
    white-space: nowrap;
    width: 1px;
}

/* ---------------------------------
Date/Time FIELD
----------------------------------*/
.nf-field-element .time-wrap{
    padding:1px;
}

/* ---------------------------------
CHECKBOX FIELD
----------------------------------*/



/* ---------------------------------
RADIO & CHECKBOX LISTS
----------------------------------*/

.list-radio-wrap,
.list-checkbox-wrap,
.list-image-wrap {
    .nf-field-element {
        ul, li {
            list-style: none !important;
            margin: 0 !important;
            padding: 0 !important;
            input {
                margin-right: 5px;
            }
        }
        li {
            padding-left: 10px;
            margin: 0 0 5px !important;
            text-indent: initial !important;
            label {
                //display: inline;
                display: block;
                float: initial;
                //margin: 0;
                width: auto;
                margin-left: 1.5em;
            }
            input {
                width: auto !important;
                float: left;
                margin-top: .25em;
            }
        }
    }
}

.listimage-wrap {
    li {
        width: 100%;
        height: 100%;
        display: inline-grid;
        label {
            padding: 5px;
            border-width: 2px;
            border-style: solid;
            border-color: transparent;
            &.nf-checked-label {
                border-width: 2px;
                border-style: solid;
                border-color: #007acc;
                transition: all .5s;
            }
            img {
                display: block;
            }
        }
        input {
            // display: none;
            &:focus + label {
                outline: 2px solid;
            }
        }
    }
}

/* ---------------------------------
PRODUCT FIELD
----------------------------------*/
.product-wrap {
    > div {
        input {
            width: 80px !important;
        }
        .nf-product-field {
            span {
                padding: 0 5px;
            }
        }
    }
}

/* ---------------------------------
STAR RATING
----------------------------------*/
.starrating-wrap .star {
    border-bottom: 0;
}

/* ---------------------------------
HONEYPOT
----------------------------------*/

.nf-form-hp {
    position: absolute;
    visibility: hidden;
}

/* ---------------------------------
SELECT LIST
----------------------------------*/
.list-select-wrap {
    div div {
        display: none;
    }
}


/* ---------------------------------
COLORS
As minimal as possible
----------------------------------*/
.ninja-forms-req-symbol,
.nf-error-msg {
    color: #E80000;
}
.nf-error {
    .ninja-forms-field {
        border: 1px solid #E80000;
    }
    &.listimage-wrap {
        .nf-field-element {
            ul {
                border: 1px solid #E80000;
            }
        }
    }
}
.nf-field-submit-error {
    padding: 15px;
}

.ninja-forms-noscript-message {
    background: #f1f1f1;
    border: 4px dashed #ccc;
    color: #333;
    display: block;
    font-size: 20px;
    margin: 20px 0;
    padding: 40px;
    text-align: center;
}

/* ---------------------------------
HELPER CLASSES
----------------------------------*/
.two-col-list {
    ul {
        li {
            float: left;
            width: 50%
        }
    }
}
.three-col-list {
    ul {
        li {
            float: left;
            width: 33.33%
        }
    }
}
.four-col-list {
    ul {
        li {
            float: left;
            width: 25%
        }
    }
}
.five-col-list {
    ul {
        li {
            float: left;
            width: 20%
        }
    }
}
.six-col-list {
    ul {
        li {
            float: left;
            width: 16.66%
        }
    }
}


body {
    .pika-label {
        background: transparent;
    }
}

/* Column Classes
    Link: http://twitter.github.io/bootstrap/assets/css/bootstrap-responsive.css
--------------------------------------------- */
.nf-form-cont {
    .five-sixths,
    .four-sixths,
    .one-fourth,
    .one-half,
    .one-sixth,
    .one-third,
    .three-fourths,
    .three-sixths,
    .two-fourths,
    .two-sixths,
    .two-thirds {
        clear: initial;
        float: left;
        margin-left: 2.564102564102564%;
        .inside {
            padding: 20px;
        }
        &::after {
            clear: both;
            content: "";
            display: block;
        }
    }

    .one-half,
    .three-sixths,
    .two-fourths {
        width: 48.717948717948715%;
    }

    .one-third,
    .two-sixths {
        width: 31.623931623931625%;
    }

    .four-sixths,
    .two-thirds {
        width: 65.81196581196582%;
    }

    .one-fourth {
        width: 23.076923076923077%;
    }

    .three-fourths {
        width: 74.35897435897436%;
    }

    .one-sixth {
        width: 14.52991452991453%;
    }

    .five-sixths {
        width: 82.90598290598291%;
    }

    .first {
        clear: both;
        margin-left: 0;
    }
}
